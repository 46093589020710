<template>
    <div class="news">
      <div class="desctop">
        <div class="banner" v-if="news.banner">
          <img :src="news.banner.image"/>
          <div class="banner__text">{{news.banner.title}}</div>
      </div>
    </div>
    <div class="mobile">
        <img src="@/assets/newImages/newsbackmobile.webp" class="mainimg1"/>
    </div>
        <div class="news__title container">
          {{ $t('head_2') }}
        </div>
        <div class="news__cards"  v-if="news">
            <div class="news__cards__card" v-for="item in news.articles" :key="item">
                <div class="news__cards__card__image" @click="$router.push('/news/' + item.slug)">
                  <img :src="item.image"/>
                </div>
                <div class="news__cards__card__title">
                    {{ item.title }}
                </div>
                <div class="lineblock4"></div>
                <div class="news__cards__card__bot">
                    <div class="news__cards__card__bot__date">
                    </div>
                    <div class="news__cards__card__bot__button">
                      <button @click="$router.push('/news/' + item.slug)">{{ $t('home_3') }}</button>
                    </div>
                </div>
                </div>
        </div>
    </div>
</template>
<script>
import { useHead } from "@vueuse/head"
export default {
  setup() {
        useHead({
            title: "Новости",
            meta: [
                { hid: 'description', name: 'description', content: 'Описание страницы' },
                { hid: 'canonical', rel: 'canonical', href: 'https://hongqi-auto.kz/news'},
            ],
        })
    },
  async created() {
    await this.$axios.get(`news`)
    .then(res => {
      this.news = res.data
    })
    .catch(err => {
    })
},
    data(){
        return{
          news:[],
            newscards:[
            {
          img: "events_1",
          title: "Шанхайская автомобильная выставка HONGQI | Коллекция моделей H9",
          date: "2023-06.29",
        },
        {
          img: "events_2",
          title: "Шанхайская автомобильная выставка HONGQI | яркие моменты моделей",
          date: "2023-04.19",
        },
        {
          img: "events_3",
          title: "Выставка автомобилей HONGQI в Шанхае  Коллекция концепт-каров",
          date: "2023-03.29",
        },
            ]
        }
    },
}
</script>
<style lang="scss" scoped>
.banner{
    position: relative;
    width: 100%;
    aspect-ratio: 2.7;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
&__text{
    position: absolute;
    color: rgb(255, 255, 255);
    font-size: min(max(18px, calc(1.125rem + ((1vw - 2.5px) * 3.2335))), 72px);
    top:20%;
    left: 15%;  
    font-weight: 700;
}
}
.lineblock4{
  border: 1px solid black;
}
    .news{
        display: flex;
        flex-direction: column;
        gap: min(max(40px, calc(2.5rem + ((1vw - 10.24px) * 1.6741))), 55px);
        padding-bottom: 70px;
        font-family: -apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,STHeiti,Microsoft YaHei,Arial;
        @media(max-width:867px){
          gap: 20px;
        }
        .mainimg1{
            width: 100%;
            object-fit: cover;
        }
        .mainimg{
            width: 100%;
            object-fit: cover;
        }
        &__title{
            text-align: center;
          font-size: min(max(20px, calc(1.25rem + ((1vw - 10.24px) * 1.6741))), 35px);
            color: #282828;
            @media(max-width:867px){
          font-size: 20px;
        }
        }
        &__cards  {
          display: flex;
          flex-wrap: wrap;
          padding: 20px;
          gap: 2%;
          row-gap: 32px;
          align-items: stretch;
          &__card{
            width: 32%;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            gap: 20px;
            font-family: -apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,STHeiti,Microsoft YaHei,Arial;
              &__image{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                aspect-ratio: 1.53;
                img{
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            &__title{
              color: #333;
              font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.558))), 17px);
              font-style: normal;
              font-weight: 700;
              line-height: 38.4px;
              flex-grow: 1;
            }
            &__bot{
              display: flex;
              justify-content: space-between;
              align-items: center;
              &__date{
                color: #282828;
                font-size: min(max(10px, calc(0.625rem + ((1vw - 10.24px) * 0.4464))), 14px);
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
              &__button{
                button{
                border: 3px solid #000;
                background: #FFF;
                transition: all 0.5s ease;
                cursor: pointer;
                &:hover{
                  background: #282828;
                  color: white;
                }
                height: min(max(45px, calc(2.8125rem + ((1vw - 10.24px) * 2.6786))), 69px);
                color: #282828;
                font-size: 15px;
                font-weight: 700;
                width: min(max(140px, calc(8.75rem + ((1vw - 10.24px) * 6.6964))), 200px);
                @media(max-width:867px){
                  height: 30px;
                  width: 130px;
                }
                }
              }
            }
          }
        }
    }
              
</style>