<template>
    <div class="world" v-if="world">
        <div class="desctop">
            <div class="banner">
                <img :src="world.banner.image"/>
                <div class="banner__text">{{world.banner.title}}</div>
            </div>
        </div>
        <div class="mobile">
            <img src="@/assets/newImages/worldbackmobile.webp" class="mainimg1" />
        </div>
        <router-link to="/history" style="text-decoration: none;">
            <div class="world__history container" :style="'background-image: url(' + world.worldCategories[0].cover_photo + ');'">
                <p>{{ world.worldCategories[0].title }}</p>
                <button class="worldbutton">{{ $t('home_3') }}</button>
            </div>
        </router-link>
        <div class="desctop">
            <div class="world__brand container">
                <div class="world__brand__left">
                    <p>{{ world.worldCategories[1].title }}</p>
                    <router-link to="/brand" style="text-decoration: none;"><button
                            class="worldbutton">{{ $t('home_3') }}</button></router-link>
                </div>
                <div class="world__brand__right">
                    <img :src="world.worldCategories[1].cover_photo" />
                </div>
            </div>
            <div class="world__development container">
                <div class="world__development__left">
                    <img :src="world.worldCategories[2].cover_photo" />
                </div>
                <div class="world__development__right">
                    <p>{{ world.worldCategories[2].title }}</p>
                    <router-link to="/develop" style="text-decoration: none;"><button
                            class="worldbutton">{{ $t('home_3') }}</button></router-link>
                </div>
            </div>
            <div class="world__achievements container">
                <div class="world__achievements__left">
                    <p>{{ world.worldCategories[3].title }}</p>
                    <router-link to="/achievement" style="text-decoration: none;"><button
                            class="worldbutton">{{ $t('home_3') }}</button></router-link>
                </div>
                <div class="world__achievements__right">
                    <img :src="world.worldCategories[3].cover_photo" />
                </div>
            </div>
            <div class="world__social container">
                <div class="world__social__left">
                    <img :src="world.worldCategories[4].cover_photo" />
                </div>
                <div class="world__social__right">
                    <p>{{ world.worldCategories[4].title }}</p>
                    <router-link to="/social" style="text-decoration: none;"><button
                            class="worldbutton">{{ $t('home_3') }}</button></router-link>
                </div>
            </div>
        </div>
        <div class="mobile">
            <router-link to="/brand" style="text-decoration: none; color: inherit;">
                <div class="brandmobile container"
                    :style="'background-image: url(' + world.worldCategories[1].cover_photo + ');'">
                    <p class="brandmobile__text">{{ world.worldCategories[1].title }}</p>
                </div>
            </router-link>
            <router-link to="/develop" style="text-decoration: none; color: inherit;">
                <div class="developmentmobile container"
                    :style="'background-image: url(' + world.worldCategories[2].cover_photo + ');'">
                    <p class="developmentmobile__text">{{ world.worldCategories[2].title }}</p>
                </div>
            </router-link>
            <router-link to="/achievement" style="text-decoration: none; color: inherit;">
                <div class="achievementsmobile container"
                    :style="'background-image: url(' + world.worldCategories[3].cover_photo + ');'">
                    <p class="achievementsmobile__text">{{ world.worldCategories[3].title }}</p>
                </div>
            </router-link>
            <router-link to="/social" style="text-decoration: none; color: inherit;">
                <div class="socialmobile container"
                    :style="'background-image: url(' + world.worldCategories[4].cover_photo + ');'">
                    <p class="socialmobile__text">{{ world.worldCategories[4].title }}Ь</p>
                </div>
            </router-link>
        </div>
    </div>
</template>
<script>
import { useHead } from "@vueuse/head"
export default {
    setup() {
        useHead({
            title: "Мир Hongqi",
            meta: [
                { hid: 'description', name: 'description', content: 'Описание страницы' },
                { hid: 'canonical', rel: 'canonical', href: 'https://hongqi-auto.kz/world'},
            ],
        })
    },
    data() {
        return {
            world: false
        }
    },
    async created() {
        this.getPage()
    },
    methods: {
        async getPage() {
            await this.$axios.get(`/world/categories?lang=ru`)
                .then(res => {
                    this.world = res.data
                })
                .catch(err => {
                })
        },
    }
}
</script>
<style lang="scss" scoped>
.banner{
    position: relative;
    width: 100%;
    aspect-ratio: 2.7;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
&__text{
    position: absolute;
    color: rgb(255, 255, 255);
    font-size: min(max(18px, calc(1.125rem + ((1vw - 2.5px) * 3.2335))), 72px);
    top:20%;
    left: 15%;  
    font-weight: 700;
}
}
.mainimg1 {
    width: 100%;
    object-fit: cover;
}

.worldbutton {
    width: min(max(150px, calc(9.375rem + ((1vw - 10.24px) * 12.7232))), 264px);

    height: min(max(30px, calc(1.875rem + ((1vw - 10.24px) * 3.125))), 58px);
    border-radius: 38.4px;
    background: #FAFCFB;
    color: #1D1D1D;
    font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.8929))), 20px);
    border: none;
    font-family: -apple-system, system-ui, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, STHeiti, Microsoft YaHei, Arial;
    text-transform: uppercase;

    @media(max-width:867px) {
        display: none;
    }
}

.achievementsmobile {
    height: 300px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    object-fit: cover;
    align-items: center;
    justify-content: center;
    gap: 50px;
    color: #FFF;
    font-size: 24.4px;
    position: relative;

    &::after {
        /* Use ::after instead of ::before */
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0.4;
        top: 0;
        left: 0;
        z-index: 0;
        /* Set z-index to ensure the overlay appears below the text */
    }

    &__text {
        z-index: 1;
        /* Set z-index to ensure the text appears above the overlay */
    }
}

.developmentmobile {
    height: 300px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: cover;
    justify-content: center;
    gap: 50px;
    color: #FFF;
    font-size: 24.4px;
    position: relative;

    &::after {
        /* Use ::after instead of ::before */
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0.4;
        top: 0;
        left: 0;
        z-index: 0;
        /* Set z-index to ensure the overlay appears below the text */
    }

    &__text {
        z-index: 1;
        /* Set z-index to ensure the text appears above the overlay */
    }
}

.brandmobile {
    height: 300px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    object-fit: cover;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    gap: 50px;
    color: #FFF;
    font-size: 24.4px;
    position: relative;

    &::after {
        /* Use ::after instead of ::before */
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0.4;
        top: 0;
        left: 0;
        z-index: 0;
        /* Set z-index to ensure the overlay appears below the text */
    }

    &__text {
        z-index: 1;
        /* Set z-index to ensure the text appears above the overlay */
    }
}

.socialmobile {
    height: 300px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    object-fit: cover;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    gap: 50px;
    color: #FFF;
    font-size: 24.4px;
    position: relative;

    &::after {
        /* Use ::after instead of ::before */
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0.4;
        top: 0;
        left: 0;
        z-index: 0;
        /* Set z-index to ensure the overlay appears below the text */
    }

    &__text {
        z-index: 1;
        /* Set z-index to ensure the text appears above the overlay */
    }
}

.world {
    display: flex;
    flex-direction: column;
    gap:  min(max(35px, calc(2.1875rem + ((1vw - 7.68px) * 3.0382))), 70px);
    padding-bottom: min(max(50px, calc(3.125rem + ((1vw - 7.68px) * 4.3403))), 100px);
    @media(max-width:867px) {
        gap: 10px;
        padding-bottom: 0px;
    }
    button{
        cursor: pointer;
    }
    &__history {
        width: 100%;
        aspect-ratio: 2.4;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
        color: #FFF;
        font-size: min(max(18px, calc(1.125rem + ((1vw - 10.24px) * 1.3839))), 30.4px);
    }

    &__brand {
        display: flex;
        &__left {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 50%;
            background: #E8E8E8;
            color: #282828;
            gap: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
            font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.6071))), 38.4px);
            height: auto;
        }

        &__right {
            width: 50%;
            aspect-ratio: 1.1;
            display: flex;
            justify-content: center;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

    }

    &__development {
        display: flex;
        &__right {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 50%;
            background: #E8E8E8;
            color: #282828;
            gap: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
            font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.6071))), 38.4px);
            height: auto;
        }

        &__left {
            width: 50%;
            aspect-ratio: 1.1;
            display: flex;
            justify-content: center;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__achievements {
        display: flex;
        &__left {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 50%;
            background: #E8E8E8;
            color: #282828;
            gap: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
            font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.6071))), 38.4px);
            height: auto;
        }

        &__right {
            width: 50%;
            aspect-ratio: 1.1;
            display: flex;
            justify-content: center;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__social {
        display: flex;

        &__right {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 50%;
            background: #E8E8E8;
            color: #282828;
            gap: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
            font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.6071))), 38.4px);
            height: auto;

        }

        &__left {
            width: 50%;
            aspect-ratio: 1.1;
            display: flex;
            justify-content: center;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}</style>